import { getDictionary, } from '~/utils/butterUtils'
import { optIn, optOut, linker } from 'vue-gtag'

export default {
  data () {
    return {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: false,
      isAllCookies: true,
      isNecessaryCookies: false,
      isCustomizeCookies: false,
      isShowCheckBoxes: false,
      isManageCookies: false,
    }
  },
  computed: {
    isProductionSite () {
      return process.env.stage === 'prod'
    },
    consentedCookies () {
      return {
        'necessary': this.necessary,
        'preferences': this.preferences,
        'statistics': this.statistics,
        'marketing': this.marketing
      }
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    cookiePolicy () {
      return this.getSiteInfo('cookie-policy')
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterSiteInfo () {
      return this.$store.state.butterSiteInfo
    },
  },
  methods: {
    acceptAllCookies () {
      this.necessary = true
      this.preferences = true
      this.statistics = true
      this.marketing = false /* 2023-06-20 VFA does not use marketing cookies */
      this.isAllCookies = true
      this.isNecessaryCookies = false
      this.isCustomizeCookies = false
      this.isManageCookies = false
    },
    acceptNecessaryCookiesOnly () {
      this.necessary = true
      this.preferences = false
      this.statistics = false
      this.marketing = false
      this.isAllCookies = false
      this.isNecessaryCookies = true
      this.isCustomizeCookies = false
      this.isManageCookies = false
    },
    acceptCustomizeCookies () {
      this.isAllCookies = false
      this.isNecessaryCookies = false
      this.isCustomizeCookies = true
      this.isManageCookies = false
    },
    manageCookies() {
      this.necessary = true
      this.preferences = true
      this.statistics = true
      this.marketing = false /* 2023-06-20 VFA does not use marketing cookies */
      this.isAllCookies = false
      this.isNecessaryCookies = false
      this.isCustomizeCookies = false
      this.isManageCookies = true
    },
    saveConsentOptions () {
      const consentOptions = Object.assign({}, this.$store.state.cookieConsent, this.consentedCookies)
      this.$store.commit('saveCookieConsent', consentOptions)
      this.$store.commit('requests/update', { cookies: consentOptions })
    },
    getSiteInfo (siteInfoId) {
      const p0 = this.butterSiteInfo
      if (p0) {
        const p1 = p0.find(item => item.id===siteInfoId)
        return { title: p1['headline_' + this.lang],
                 body: p1['text_' + this.lang]
        }
      } else {
        return { title: '',
                 body: ''
        }
      }
    },

    /**
     * 2023-06-04 John Yee
     * must enable/disable the selected options
     * 
     * start/stop services; create/remove cookies
     * in particular, remove cookies for disabled features
     */
    configureConsent () {
      const cookieConsent = this.$store.state.cookieConsent

      if (cookieConsent.necessary) {
        // install necessary cookies

        this.startIntercom()
      } else {
        // remove necessary cookies

        this.stopIntercom ()
      }

      if (cookieConsent.preferences) {
        // install preferences cookies
      } else {
        // remove preferences cookies
      }

      if (cookieConsent.statistics) {
        // install statistics cookies

        if (this.isProductionSite) {
          // Facebook Pixel:: 2024-04-23 John Yee: ExecComm to decide whether or not to allow Facebook Pixel.
          //                  2024-04-29 John Yee: enabling so DA Mexico can test
          console.log("CookieConsentBanner: Production site and user approved statistics cookies; enabling Facebook Pixel.")
          this.startFacebookPixel()

          console.log("CookieConsentBanner: Production site and user approved statistics cookies; enabling Google Analytics.")
          this.startGoogleAnalytics()
        } else {
          console.log("CookieConsentBanner: Not production site; Google Analytics not initialized.")
          // Don't need to stop it because it was never started.
          // this.stopGoogleAnalytics()
        }

      } else {
        // remove statistics cookies

        /*
          2024-04-23 John Yee: ExecComm to decide whether or not to allow Facebook Pixel.
        */
        /*
          2024-05-01 John Yee
          Bug: On my Windows 11 | Windows Subsystem for Linux | "npm run dev", launch
          Firefox ...
          If this.stopFacebookPixel() is called, then on localhost and vfa-newux when "MANAGE"
          cookies is chosen and "Statistics" is unchecked sometimes the website will disappear and
          exit to the browser.

          Possible Fix: Comment out this.stopFacebookPixel()
          I think the fix is safe and does not introduce any consequences because consent should
          already be revoked per code in layouts/default.vue

          Speculation: Is the problem due to fbq('consent', 'revoke') not working nice when it has
          already been called?

          Notes:
          (1) Clearing browser history will "fix" the problem for a few launches of the website;
          but, the problem will return.
          (2) Will happen using Chrome, Windows 11 when launching directly to 
          localhost:3000/cookie-policy/ or https://vfa-newux.netlify.app/cookie-policy/
          (3) Heidi, Michele, and Will have tested on various browsers and OSs and devices.
          Everything works for them - no problems occurred.
          Hence, leaving the code alone.  BUT - we must be watchful!
        */
        this.stopFacebookPixel()

        this.stopGoogleAnalytics()
      }

      if (cookieConsent.marketing) {
        // install marketing cookies
        // 2023-06-05
        // VFA does not install any marketing cookies
      } else {
        // remove marketing cookies
      }
    },

    startFacebookPixel () {
      /**
       * 2024-04-28 John Yee
       * Facebook javascript setup in layouts/default.vue head()
       */
      try {
        console.log("CookieConsentBanner: Enabling Facebook Pixel.")
        fbq('consent', 'grant');
      } catch (error) {
        console.log("CookieConsentBanner:  Error - Facebook Pixel not enabled.")
        console.error(error)
      }
    },
    stopFacebookPixel () {
      try {
        console.log("CookieConsentBanner: Disabling Facebook Pixel.")
        fbq('consent', 'revoke');
        this.removeCookies("_fbp")
      } catch (error) {
        console.log("CookieConsentBanner: Error - Facebook Pixel not disabled.")
        console.error(error)
      }
    },

    startGoogleAnalytics () {
      try {
        console.log("CookieConsentBanner: Enabling Google Analytics (GA4).")
        // setOptions({config: {'id':'G-9EYFRF6GED'}})  // setting this in initialization seems to be working well
        optIn()
        // better to set this using the GA UI, but do it here in the meantime
        linker({
          'domains': [
            'beta.votefromabroad.org',
            'students.votefromabroad.org',
            'es.votefromabroad.org',
            'vr.votefromabroad.org',
            'votefromabroad.org'
          ]
        });
        
        // see https://developers.google.com/tag-platform/security/guides/consent?sjid=14576639834216268942-EU&consentmode=advanced
        this.$gtag.query('consent', 'default', {
          'ad_storage': 'granted',
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
          'analytics_storage': 'granted'
        });
        
      } catch (error) {
        console.log("CookieConsentBanner: Error - Google Analytics (GA4) not enabled.")
        console.error(error)
      }
    },
    stopGoogleAnalytics () {
      try {
        console.log("CookieConsentBanner: Disabling Google Analytics (GA4).")
        optOut()
        
        // see https://developers.google.com/tag-platform/security/guides/consent?sjid=14576639834216268942-EU&consentmode=advanced
        this.$gtag.query('consent', 'update', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied'
        });
        
      } catch (error) {
        console.log("CookieConsentBanner: Error - Google Analytics (GA4) not disabled.")
        console.error(error)
      }
    },

    startIntercom () {
      /*
        2023-06-04 John Yee
        Intercom Chatbot

        note: on first start, session cookie is created
              stopping removes session cookie
              second (and subsequent starts) do not restore the old session cookie or create a new one

        note: must give appId to boot method even though appId is given in the intercom.js plugin
              but do not have to give appId to boot method in layouts/default.vue

        note: to ask - what is the "h27cpfij" account?
      */
      try {
        console.log("CookieConsentBanner: Start Intercom.")
        this.$intercom.boot({ appId: process.env.dev === 'dev' ? 'h27cpfij' : 't392eszv' })
      } catch (error) {
        console.log("CookieConsentBanner: Error - Intercom not started.")
        console.error(error)
      }
    },
    stopIntercom () {
      try {
        console.log("CookieConsentBanner: Stop Intercom.")
        this.$intercom.shutdown()
        this.removeCookies("intercom-")
      } catch (error) {
        console.log("CookieConsentBanner: Error - Intercom not stopped.")
        console.error(error)
      }
    },

    removeCookies (cookiePrefix) {
      const url = process.env.url
      const domain = url.includes(".votefromabroad.org") ? ".votefromabroad.org" : ( url.includes(".netlify.app") ? url.split("//")[1] : "localhost" )

      if (document.cookie.includes(cookiePrefix)) {
        // two methods to remove cookies because each method fails sometimes
        // hopefully one method will remove a cookie when the other method fails
        document.cookie
          .split(";").filter(x=>x.includes(cookiePrefix))
          .map(x=>x+"; max-age=0; path=/; domain="+domain)
          .forEach(x=>document.cookie=x)

        // 2024-06-19 John Yee: with thanks to ...
        //    Can't delete cookies from my domain #597 
        //    https://github.com/js-cookie/js-cookie/issues/597
        document.cookie
          .split(";").filter(x=>x.includes(cookiePrefix))
          .map(x=>x+"=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;")
          .forEach(x=>document.cookie=x)
      }
    },
  },
  mounted () {
    this.removeCookies("_fbp")
    this.removeCookies("_ga")
    this.removeCookies("intercom-")

    const cookieConsent = this.$store.state.cookieConsent
    this.necessary = cookieConsent.necessary
    this.preferences = cookieConsent.preferences
    this.statistics = cookieConsent.statistics
    this.marketing = cookieConsent.marketing

    /*
      2024-07-22 John Yee
      per technical team meeting 

      on launching the site, the default consent for all cookies and pixels is YES 
      i.e. cookies and pixels are started, consent is granted
    */
    this.startIntercom()

  	if (this.isProductionSite) {
      // VFA has the accounts/ids only for the production sites
      this.startFacebookPixel()
      this.startGoogleAnalytics()
    }
  },
}
